import React from 'react'
import NavBar from '../components/NavBar'

const foradults = () => {
    return (
        <div>
            <NavBar />
            <div>foradults</div>
        </div>
    )
}

export default foradults